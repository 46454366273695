<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12">
        <table
          class="table table-bordered table-striped table-hover table-sm text-xs"
        >
          <thead class="bg-gray text-center">
            <tr>
              <th class="text-nowrap">
                Acciones
                <button
                  type="button"
                  class="btn btn-sm bg-primary text-white"
                  id="agregarRuta"
                  @click="addRuta(0)"
                  :disabled="
                    estado_ruta == 1 ||
                      !$parent.form.id ||
                      $parent.form.estado == 2 ||
                      $parent.form.estado == 3 ||
                      $parent.form.estado == 4
                  "
                  style="cursor: pointer"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </th>
              <th>Tipo Sitio</th>
              <th>Sitio</th>
              <th>Direccion</th>
              <th>Nombre Contacto</th>
              <th>Número de contacto</th>
              <th>Correo</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="(rut, index) in rutas" :key="rut.id">
              <td class="text-center">
                <div class="btn-group" v-if="estado_ruta != 2">
                  <button
                    type="button"
                    class="btn bg-navy"
                    :disabled="
                      estado_ruta != -1 ||
                        $parent.form.estado == 2 ||
                        $parent.form.estado == 3 ||
                        $parent.form.estado == 4
                    "
                    @click="editRuta(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    :disabled="
                      estado_ruta != -1 ||
                        $parent.form.estado == 2 ||
                        $parent.form.estado == 3 ||
                        $parent.form.estado == 4
                    "
                    @click="destroyRuta(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div
                  class="btn-group"
                  v-if="estado_ruta == 2 && id_actualizar_ruta == index"
                >
                  <button
                    type="button"
                    class="btn bg-success"
                    v-show="!$v.ruta.$invalid"
                    @click="saveUpdateRuta(index)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelRuta()">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div
                  class="btn-group"
                  v-if="estado_ruta == 2 && id_actualizar_ruta !== index"
                >
                  <button
                    type="button"
                    class="btn bg-navy"
                    disabled
                    @click="editRuta(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    disabled
                    @click="destroyRuta(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                  <select
                    placeholder="Tipo sitio"
                    label="nombres"
                    class="form-control form-control-sm p-0"
                    v-model="ruta.tipo_sitio"
                    :class="
                      $v.ruta.tipo_sitio.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option value="1">Origen</option>
                    <option value="2">Destino</option>
                  </select>
                </div>
                <div v-else>
                  <span
                    class="badge"
                    :class="rut.tipo_sitio == 1 ? 'bg-success' : 'bg-primary'"
                  >
                    {{ rut.tipo_sitio == 1 ? "Origen" : "Destino" }}
                  </span>
                </div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nombre"
                    v-model="ruta.nombre"
                    :class="$v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <div v-else>{{ rut.nombre }}</div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="direccion"
                    v-model="ruta.direccion"
                    :class="
                      $v.ruta.direccion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ rut.direccion }}</div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="nombre_contacto"
                    v-model="ruta.nombre_contacto"
                    @input="validarLetra(ruta.nombre_contacto, 1)"
                    :class="
                      $v.ruta.nombre_contacto.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ rut.nombre_contacto }}</div>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_ruta === index && estado_ruta === 2">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="telefono_contacto"
                    v-model="ruta.telefono_contacto"
                    :class="
                      $v.ruta.telefono_contacto.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ rut.telefono_contacto }}</div>
              </td>
              <td class="text-center">
                <div
                  class="input-group mb-3"
                  v-if="id_actualizar_ruta === index && estado_ruta === 2"
                >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    v-model="ruta.email_contacto"
                    id="email_contacto"
                    :class="
                      $v.ruta.email_contacto.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    placeholder="Email"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>

                <div v-else>{{ rut.email_contacto }}</div>
              </td>
            </tr>
            <tr v-if="estado_ruta === 1">
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn bg-success"
                    v-show="!$v.ruta.$invalid"
                    @click="saveRuta()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelRuta()">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
              <td>
                <select
                  placeholder="Tipo sitio"
                  label="nombres"
                  class="form-control form-control-sm p-0"
                  v-model="ruta.tipo_sitio"
                  v-on:input="$v.ruta.tipo_sitio.$touch"
                  :class="
                    $v.ruta.tipo_sitio.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option value="1">Origen</option>
                  <option value="2">Destino</option>
                </select>
                <div
                  v-if="
                    (!$v.ruta.tipo_sitio.$dirty ||
                      $v.ruta.tipo_sitio.$invalid) &&
                      (ruta.tipo_sitio == '' || ruta.tipo_sitio == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="nombre"
                  v-model="ruta.nombre"
                  v-on:input="$v.ruta.nombre.$touch"
                  :class="$v.ruta.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                />
                <div
                  v-if="
                    (!$v.ruta.nombre.$dirty || $v.ruta.nombre.$invalid) &&
                      (ruta.nombre == '' || ruta.nombre == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="direccion"
                  v-model="ruta.direccion"
                  v-on:input="$v.ruta.direccion.$touch"
                  :class="
                    $v.ruta.direccion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <div
                  v-if="
                    (!$v.ruta.direccion.$dirty || $v.ruta.direccion.$invalid) &&
                      (ruta.direccion == '' || ruta.direccion == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="nombre_contacto"
                  v-model="ruta.nombre_contacto"
                  @input="validarLetra(ruta.nombre_contacto, 2)"
                  v-on:input="$v.ruta.nombre_contacto.$touch"
                  :class="
                    $v.ruta.nombre_contacto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <div
                  v-if="
                    (!$v.ruta.nombre_contacto.$dirty ||
                      $v.ruta.nombre_contacto.$invalid) &&
                      (ruta.nombre_contacto == '' ||
                        ruta.nombre_contacto == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </td>
              <td>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="telefono_contacto"
                  v-model="ruta.telefono_contacto"
                  v-on:input="$v.ruta.telefono_contacto.$touch"
                  :class="
                    $v.ruta.telefono_contacto.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
                <div
                  v-if="
                    (!$v.ruta.telefono_contacto.$dirty ||
                      $v.ruta.telefono_contacto.$invalid) &&
                      (ruta.telefono_contacto == '' ||
                        ruta.telefono_contacto == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
                <div
                  v-if="
                    $v.ruta.telefono_contacto.$invalid &&
                      ruta.telefono_contacto != '' &&
                      ruta.telefono_contacto != null
                  "
                >
                  <span class="text-danger">
                    <small> *Teléfono no valido </small>
                  </span>
                </div>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="email_contacto"
                  v-model="ruta.email_contacto"
                  v-on:input="$v.ruta.email_contacto.$touch"
                  :class="
                    $v.ruta.email_contacto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <div
                  v-if="
                    (!$v.ruta.email_contacto.$dirty ||
                      $v.ruta.email_contacto.$invalid) &&
                      (ruta.email_contacto == '' || ruta.email_contacto == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
                <div
                  v-if="
                    !isValidEmail &&
                      ruta.email_contacto != '' &&
                      ruta.email_contacto != null
                  "
                >
                  <span class="text-danger">
                    <small> *Correo no valido </small>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12 text-center">
        <h6 v-if="!(ruta_descargue == 1 && ruta_cargue == 1)">
          <span class="badge badge-warning"
            ><i class="fas fa-times-circle"></i> Recuerde que es obligatorio
            agregar sitio de origen y destino</span
          >
        </h6>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CsSolicitudesInternasRutas",
  components: {},
  data() {
    return {
      rutas: [],
      ruta: {
        id: null,
        cs_solicitud_interna_id: null,
        tipo_sitio: null,
        nombre: null,
        direccion: null,
        nombre_contacto: null,
        telefono_contacto: null,
        email_contacto: null,
      },
      estado_ruta: -1,
      ruta_cargue: 0,
      ruta_descargue: 0,
      id_actualizar_ruta: -1,
    };
  },
  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.ruta.email_contacto);
    },
  },
  validations: {
    ruta: {
      tipo_sitio: {
        required,
      },
      nombre: {
        required,
      },
      direccion: {
        required,
      },
      nombre_contacto: {
        required,
      },
      telefono_contacto: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      email_contacto: {
        required,
        email,
      },
    },
  },
  methods: {
    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1) {
            this.ruta.nombre_contacto = palabra.slice(0, -1);
          }

          if (opcion == 2) {
            this.ruta.nombre_contacto = palabra.slice(0, -1);
          }
        }
      }
    },

    async getRutas() {
      this.$parent.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/solicitudesInternasRuta", {
          params: { solicitudInterna_id: this.$parent.form.id },
        })
        .then(async (response) => {
          this.rutas = response.data;
          await this.calcularRutas();
          this.$parent.cargando = false;
        });
    },

    async calcularRutas() {
      this.$parent.cargando = true;
      this.ruta_cargue = 0;
      this.ruta_descargue = 0;
      this.rutas.forEach((element) => {
        if (element.tipo_sitio == 1) {
          this.ruta_cargue++;
        }
        if (element.tipo_sitio == 2) {
          this.ruta_descargue++;
        }
      });
      this.$parent.cargando = false;
    },

    async addRuta(opcion) {
      //console.log(opcion);
      await this.limpiarRuta(opcion);
      this.ruta.cs_solicitud_interna_id = this.$parent.form.id;
      this.estado_ruta = 1;
    },

    async limpiarRuta(opcion) {
      this.ruta = {
        id: null,
        cs_solicitud_interna_id: null,
        tipo_sitio:
          opcion == 1 ? 2 : opcion == 2 ? 1 : opcion == 0 ? null : null,
        nombre: null,
        direccion: null,
        nombre_contacto: null,
        telefono_contacto: null,
        email_contacto: null,
      };
    },

    async editRuta(index) {
      this.id_actualizar_ruta = index;
      this.estado_ruta = 2;
      this.ruta = { ...this.rutas[index] };
    },

    async cancelRuta() {
      this.estado_ruta = -1;
    },

    async saveRuta() {
      this.rutas.push(this.ruta);
      await this.calcularRutas();
      this.estado_ruta = -1;
      this.estado_ruta = 1;
      await this.addRuta(this.ruta.tipo_sitio);
    },

    async saveUpdateRuta(index) {
      this.rutas[index] = this.ruta;
      this.estado_ruta = -1;
      this.id_actualizar_ruta;
      this.addRuta(this.ruta.tipo_sitio);
      await this.calcularRutas();
    },

    async saveRutaTotal() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/funcionariosFrontera/solicitudesInternasRuta",
        data: {
          cs_solicitud_interna_id: this.$parent.form.id,
          rutas: this.rutas,
        },
      })
        .then(async (response) => {
          await this.getRutas();
          this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async destroyRuta(index) {
      await this.rutas.splice(index, 1);
      await this.calcularRutas();
    },
  },
};
</script>
